.ClaimListItem {
    margin-bottom: 2px;
}

.ClaimListItem-drug {
    background: #f9f9f9;
}

.ClaimListItem-icon {
    color: #3f51b5 !important;
}