body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.MainContent {
    margin: 10px;
    padding-bottom: 56px;
}

.print-only {
    display: none;
}

.MuiSwitch-switchBase {
    padding: 9px !important; /* fix bug in MaterialUI css order */
}


@media print {
    .Card {
        box-shadow: none !important;
    }

    .MemberCardHeader {
        display: block;
    }

    .MainContent {
        margin: 0;
        padding: 0;
    }

    .CardContent {
        padding: 0 !important;
    }

    .ClaimListItem {
        padding: 0 !important;
        border-bottom: 1px solid black;
        page-break-inside: avoid;
    }

    .ClaimListItem:last-child {
        border-bottom: none;
    }

    .MemberCard-print {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid black;
    }

    .screen-only {
        display: none !important;
    }

    .print-only {
        display: block;
    }
}